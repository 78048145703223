import React, {useEffect, useRef, useState } from 'react'
import { PopUp, Typography } from 'components'
import { Tooltip } from '@mui/material'
import { PREVIEWABLE_DOC_TYPES, PREVIEWABLE_TYPES, PREVIEWABLE_VIDEO_TYPES } from 'helpers/constants'
import vars from '../../vars.scss'
import styles from './announcements.module.scss'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const AttachmentViewer = ({ attachment, onCancel, open }) => {
  const [zoom, setZoom] = useState(1)
  const fileExtension = attachment?.file_name?.split('.').pop()?.toLowerCase()
  const isAndroidDevice = /Android/i.test(navigator.userAgent)

  const [position, setPosition] = useState({x:0, y:0})
  const imageRef = useRef(null)
  const handleZoomIn = () => setZoom(zoom + 0.1)
  const handleZoomOut = () =>{ 
    if(zoom > 0.3){
      setZoom(zoom > 0.3 ? zoom - 0.1 : zoom)
    }
    }

useEffect(()=> {
const image = imageRef.current;
let isDragging = false;
let prevPosition = {x:0, y:0 };

//move down event handler for starting image drag
const handleMouseDown =(e)=>{
  isDragging=true;
  prevPosition={ x:e.clientX, y: e.clientY };
}

// Mouse move event handler for dragging the image

const handleMouseMove =(e) =>{
  if(!isDragging) return;
  const deltaX = e.clientX - prevPosition.x;
  const deltaY = e.clientY - prevPosition.y;
  prevPosition = { x: e.clientX, y: e.clientY};
  setPosition((position)=>({
    x:position.x + deltaX,
    y:position.y + deltaY,
  }));
};

//Mouse up event handeler for ending image drag
const handleMouseUp =()=>{
  isDragging = false;
}

//add event listeners
  image?.addEventListener("mousedown", handleMouseDown);
  image?.addEventListener("mousemove", handleMouseMove);
  image?.addEventListener("mouseup", handleMouseUp);

  //Remove event listenrs on component unmount
  return () => {
    image?.removeEventListener("mousedown", handleMouseDown);
    image?.removeEventListener("mousemove", handleMouseMove);
    image?.removeEventListener("mouseup", handleMouseUp);
  };

},[imageRef, zoom ])

  return (
    <PopUp
      title={
        <>
          <Typography.Heading type={6}>Preview</Typography.Heading>
          <Tooltip placement="bottom-start" title={attachment?.file_name.length > 30 ? attachment?.file_name : ''}>
            <div>
              <Typography.Body style={{ color: vars['table-cell-color'] }} type={2}>
                {attachment?.file_name.length > 30 ? `${attachment?.file_name.slice(0, 30)}...` : attachment?.file_name}
              </Typography.Body>
            </div>
          </Tooltip>
        </>
      }
      open={open}
      style = {{ width: '90%', maxWidth: 'none' } }
      maxWidth={!PREVIEWABLE_DOC_TYPES.includes(fileExtension) && "lg"}
      canShowCancelButton={false}
      onCancel={onCancel}
      canShowConfirmButton={false}>
      <div className={styles.attachmentPreview}>
        {PREVIEWABLE_DOC_TYPES.includes(fileExtension) ? (
          isAndroidDevice ? (
            <div className={styles.inValidFileType}>
              <Typography.Body style={{ color: vars['table-cell-color'] }} type={2}>
                Android does not allow preview of PDF or Word documents. Please download the document or use a desktop to preview.
              </Typography.Body>
            </div>
          ) : (
            <iframe title={attachment?.file_name} src={attachment?.signedUrl} className={styles.docs} />
          )
        ) : PREVIEWABLE_TYPES.includes(fileExtension) ? (
          <div className={styles.imageContainer}>
            <img
              ref={imageRef}
              className={styles.image}
              src={attachment?.signedUrl}
              alt="img"
              style={{ transform: `scale(${zoom}) translate(${position.x}px, ${position.y}px)`,
                 cursor: "move"
               }}
               draggable={false}
            />
            <div className={styles.zoomControls}>
              <button onClick={handleZoomIn}><AddCircleOutlineIcon /></button>
              <button onClick={handleZoomOut}><RemoveCircleOutlineIcon /></button>
            </div>
          </div>
        ) : PREVIEWABLE_VIDEO_TYPES.includes(fileExtension) ? (
          <div className={styles.videoCenter}>
          <video controls className={styles.video}>
            <source src={attachment?.signedUrl} type={`video/${fileExtension}`} />
            Your browser does not support the video tag.
          </video>
          </div>
        ) : (
          <div className={styles.inValidFileType}>
            <Typography.Body style={{ color: vars['table-cell-color'] }} type={2}>
              This file type is not supported
            </Typography.Body>
          </div>
        )}
      </div>
    </PopUp>
  )
}

export default AttachmentViewer
