import * as ReactDOMClient from 'react-dom/client'
import { ApolloProvider } from '@apollo/client'
import Routes from './Routes'
import { BrowserRouter } from 'react-router-dom'
import { UserProvider } from 'context'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { CometChat } from '@cometchat-pro/chat'
import { ToastContainer, toast } from 'react-toastify'
import client from 'helpers/client'
import './index.scss'
import 'react-toastify/dist/ReactToastify.css'

// Note: Comet Chat Commented out as we are not using it
const appSetting = new CometChat.AppSettingsBuilder()
  .subscribePresenceForAllUsers()
  .setRegion(process.env.REACT_APP_COMETCHAT_REGION)
  .build()
CometChat.init(process.env.REACT_APP_COMETCHAT_APPID, appSetting).then(
  () => {
    console.log('Initialization completed successfully')
    // You can now call login function.
  },
  (error) => {
    console.log('Initialization failed with error:', error)
    // Check the reason for error and take appropriate action.
  }
)

const defaultToastOptions = {
  success: {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
  },
  error: {
    position: 'top-center',
    autoClose: 15000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
  },
};

// Extend default toast methods globally
Object.entries(defaultToastOptions).forEach(([type, options]) => {
  toast[type] = ((original) => (content, customOptions) => {
    original(content, { ...options, ...customOptions });
  })(toast[type]);
});

const container = document.getElementById('root')
const root = ReactDOMClient.createRoot(container)
root.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <HelmetProvider>
        <UserProvider>
          <Helmet title="Cloud | RARE">
            <script>
              {`
                window['_fs_host'] = 'fullstory.com'; 
                window['_fs_script'] = 'edge.fullstory.com/s/fs.js'; 
                window['_fs_org'] = 'o-1M930P-na1'; 
                window['_fs_namespace'] = 'FS'; 
                (function(m,n,e,t,l,o,g,y){ 
                  if (e in m) {if(m.console && m.console.log) 
                    { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');} return;}
                     g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[]; 
                     o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';
                     o.src='https://'+_fs_script; y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y); 
                     g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};
                     g.event=function(i,v,s){g('event',{n:i,p:v},s)}; g.anonymize=function(){g.identify(!!0)};
                     g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)}; 
                     g.log = function(a,b){g("log",[a,b])}; g.consent=function(a){g("consent",!arguments.length||a)}; 
                     g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)}; g.clearUserCookie=function(){};
                     g.setVars=function(n, p){g('setVars',[n,p]);}; g._w={};y='XMLHttpRequest';g._w[y]=m[y];y='fetch';g._w[y]=m[y]; 
                     if(m[y])m[y]=function(){return g._w[y].apply(this,arguments)}; g._v="1.3.0"; 
                    })(window,document,window['_fs_namespace'],'script','user');
              `}
            </script>
          </Helmet>
          <Routes />
          <ToastContainer
            autoClose // Global autoClose for non-specified types
            hideProgressBar={true}
            closeOnClick
            pauseOnFocusLoss
            draggable= {false}
            pauseOnHover
          />
        </UserProvider>
      </HelmetProvider>
    </BrowserRouter>
  </ApolloProvider>
);
