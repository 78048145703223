import { gql, useMutation, useQuery } from '@apollo/client'
import { Typography, Loading, BasicTable, Row, Col, Button, Avatar, InputSearch, Tag, WarningPopup } from 'components'
import { useCallback, useContext, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import {
  LISTING_TYPES,
  STATUS,
  STATUS_COLORS,
  STATUS_TYPES,
  ACTION_OPTIONS,
  USER_ROLES,
  AGENT_COLORS
} from 'helpers/constants'
import dropdownOption from 'helpers/dropdownOptions'
import search from 'helpers/search'
import { GET_LISTINGS } from 'helpers/gql'
import CreateListing from './create_listing/CreateListing'
import { convertUTCToLocal, getFileUrl, isAdmin } from 'helpers/utils'
import { CustomTabs, MUIActionButton } from '../../components'
import { UserContext } from 'context'
import { useNavigate } from 'react-router-dom'
import { usePopup } from 'hooks/usePopup'
import { toast } from 'react-toastify'
import styles from './listings.module.scss'
import vars from '../../vars.scss'
import { useMemo } from 'react'
import debounce from 'lodash.debounce'

const Listings = () => {
  const navigate = useNavigate()
  const { user } = useContext(UserContext)
  const [filters, setFilters] = useState({})
  const [createOpen, setCreateOpen] = useState(false)
  const [selectedListingType, setSelectedListingType] = useState('')
  const [rows, setRows] = useState()
  const [selectedRows, setSelectedRows] = useState([])
  const [confirmDelete, showConfirmDelete, hideConfirmDelete] = usePopup()
  const [checkedRows, setCheckedRows] = useState()
  const [isListingLinked, setIsListingLinked] = useState(false)
  const [rowsPerPage, setrowsPerPage] = useState(100)

  const {
    data: { getListings: listings } = [],
    loading,
    refetch
  } = useQuery(GET_LISTINGS, {
    fetchPolicy: 'no-cache',
    variables: {
      filters
    },
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
    },
    onCompleted: (data) => {
      setRows(data.getListings)
    }
  })

  const [deleteListing, { loading: deleteLoading }] = useMutation(DELETE_LISTING, {
    onError: (err) => {
      toast.dismiss()
      if (!err?.message?.includes('Session timeout')) {
        toast.error(err.message)
      }
      hideConfirmDelete()
    },
    onCompleted: () => {
      toast.dismiss()
      setCheckedRows([])
      setSelectedRows([])
      toast.success('Listing(s) deleted.')
      refetch()
      setIsListingLinked(false)
      hideConfirmDelete()
    }
  })

  const getActionOptions = (status) => {
    const canShowDeleteMenu =
      (status === STATUS_TYPES.draft && user.role === USER_ROLES.agent) ||
      user.role === USER_ROLES.admin ||
      user.role === USER_ROLES.super_admin

    const options = [
      { id: ACTION_OPTIONS.view, text: ACTION_OPTIONS.view },
      status !== STATUS_TYPES.review && { id: ACTION_OPTIONS.edit, text: ACTION_OPTIONS.edit },
      canShowDeleteMenu && { id: ACTION_OPTIONS.delete, text: ACTION_OPTIONS.delete }
    ]
    return options.filter(Boolean)
  }

  const handleOnClickActionButton = (value, publicId) => {
    const listing = listings.find((listing) => listing.public_id === publicId)
    if (ACTION_OPTIONS.view === value) {
      navigate(`/listings/view/${listing.option}/${publicId}`)
    } else if (ACTION_OPTIONS.edit === value) {
      navigate(`/listings/edit/${listing.option}/${publicId}`)
    } else if (ACTION_OPTIONS.delete === value) {
      setSelectedRows([publicId])
      showConfirmDelete()
    }
  }

  const renderDeleteListingContent = () => {
    const ret = selectedRows.map((listingToDelete, index) => {
      const filteredListing = listings.find((listing) => listing.public_id === listingToDelete)
      if (filteredListing && filteredListing?.linked_deal !== null) {
        if (!isListingLinked) setIsListingLinked(true)
        return (
          <Typography.Body key={index} type={1} style={{ textAlign: 'center' }}>
            <span style={{ color: vars.requiredIcon }}>{filteredListing.address}</span>
            <span style={{ color: vars['font-color-secondary'] }}> is added to deal Id </span>
            <span style={{ color: vars.requiredIcon }}>{filteredListing?.linked_deal}</span>
          </Typography.Body>
        )
      }
      return null
    })
    return ret
  }

  const canDelete = useMemo(() => {
    return (user.role === USER_ROLES.agent && filters.status === STATUS_TYPES.draft) ||
      user.role === USER_ROLES.admin ||
      user.role === USER_ROLES.super_admin
      ? true
      : false
  }, [filters.status, user.role])

  const tabOptions = useMemo(() => {
    return user.role === USER_ROLES.admin || user.role === USER_ROLES.super_admin
      ? dropdownOption.listingFilterOptions.filter(
          (opt) => opt.value !== STATUS_TYPES.draft && opt.value !== STATUS_TYPES.settled
        )
      : dropdownOption.listingFilterOptions.filter((opt) => opt.value !== STATUS_TYPES.settled)
  }, [user.role])

  const columns = [
    {
      name: 'id',
      label: 'id',
      options: {
        display: false
      }
    },
    {
      name: 'public_id',
      label: '',
      options: {
        display: false
      }
    },
    {
      name: 'creator_id',
      label: '',
      options: {
        display: false
      }
    },
    {
      name: 'agents',
      label: 'Agent',
      options: {
        sortCompare: (order) => (rowA, rowB) => {
          const [public_id1, id1, creator_id1] = rowA.rowData
          const dealAgentA = rowA?.data?.filter((a) => a.id === (isAdmin(user) ? creator_id1 : user.id))[0]

          const [public_id2, id2, creator_id2] = rowB.rowData
          const dealAgentB = rowB?.data?.filter((a) => a.id === (isAdmin(user) ? creator_id2 : user.id))[0]

          const agentNameA = dealAgentA?.name.toUpperCase()
          const agentNameB = dealAgentB?.name.toUpperCase()
          if (agentNameA < agentNameB) {
            return order === 'asc' ? -1 : 1
          }
          if (agentNameA > agentNameB) {
            return order === 'asc' ? 1 : -1
          }
          return 0
        },
        customBodyRender: (value, tableMeta) => {
          const [id, public_id, creator_id] = tableMeta.rowData
          const listingAgent = value.filter((a) => a.id === (isAdmin(user) ? creator_id : user.id))[0]
          return (
            <div className={styles.agentColumn}>
              {listingAgent && (
                <>
                  <Avatar name={listingAgent.name} src={getFileUrl(listingAgent.thumbnail)} />
                  <div>
                    <Typography.Body className={styles.agentName}>{listingAgent.name}</Typography.Body>
                    <Typography.Body style={{ color: vars['loading-inner-color-secondary'] }}>
                      {listingAgent.id === creator_id ? (
                        <Tag
                          text={`Primary Rare Agent #${listingAgent.id}`}
                          background={`${AGENT_COLORS['primary']}`}
                        />
                      ) : (
                        <Tag
                          text={`Secondary Rare Agent #${listingAgent.id}`}
                          background={`${AGENT_COLORS['secondary']}`}
                        />
                      )}
                    </Typography.Body>
                  </div>
                </>
              )}
            </div>
          )
        }
      }
    },
    {
      name: 'price',
      label: 'Price',
      options: {
        display: false
      }
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        sort: true,
        customBodyRender: (value, tableMeta) => {
          const [id] = tableMeta.rowData
          const fontColor = tableMeta.rowData[11] === false ? 'red' : 'initial'
          return (
            <div className={styles.typeColumn}>
              <Typography.Body style={{ color: fontColor }}>{value ? LISTING_TYPES[value] : ''}</Typography.Body>
              <Typography.Body style={{ color: fontColor }}>#{id}</Typography.Body>
            </div>
          )
        }
      }
    },
    {
      name: 'status',
      label: '',
      options: {
        display: false
      }
    },
    {
      name: 'status_pretty_name',
      label: 'Status',
      options: {
        sort: true,
        customBodyRender: (value, tableMeta) => {
          const [id, public_id, creator_id, agents, price, type, status] = tableMeta.rowData
          return <Tag className={styles.statusColumn} text={value} background={`${STATUS_COLORS[status]}`} />
        }
      }
    },
    {
      name: 'address',
      label: 'Listing',
      options: {
        sort: true,
        customBodyRender: (value) => {
          return <Typography.Body style={{ color: vars['table-cell-color'] }}>{value}</Typography.Body>
        }
      }
    },
    {
      name: 'created',
      label: 'Created Date',
      options: {
        sort: true,
        customBodyRender: (value) => {
          return (
            <Typography.Body style={{ color: vars['table-cell-color'] }}>
              {convertUTCToLocal(value, 'MMM DD, yyyy')}
            </Typography.Body>
          )
        }
      }
    },
    {
      name: 'status',
      label: ' ',
      options: {
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const [option, publicId] = tableMeta.rowData
          const actionOptions = getActionOptions(value)
          return (
            <MUIActionButton
              options={actionOptions}
              handleOnClickActionButton={(menu) => handleOnClickActionButton(menu, publicId)}
            />
          )
        }
      }
    },
    {
      name: 'checklist_tasks_completed',
      label: '',
      options: {
        display: false,
        sort: false
      }
    }
  ]

  const handleStatusFilterChange = async (status) => {
    setrowsPerPage(100)
    setCheckedRows([])
    setSelectedRows([])
    if (status === 'all') return setFilters({})
    setFilters({
      status
    })
  }

  const handleSearch = useCallback(
    debounce(async (searchedVal) => {
      const filteredRows = search.ListingTableFilterRowsBySearch(listings, searchedVal)
      setRows(filteredRows)
    }, 300),
    [listings]
  )

  const handleRowSelect = useCallback(
    (currentRowsSelected, allRowsSelected, selectedRows) => {
      const rowsToDelete = []
      allRowsSelected.map((row) => {
        const selectedRows = rows.at(row.index)
        return rowsToDelete.push(selectedRows.public_id)
      })
      setSelectedRows(rowsToDelete)
      setCheckedRows(selectedRows)
    },
    [rows]
  )

  const handleMultipleDelete = useCallback(() => {
    deleteListing({
      variables: { id: selectedRows }
    })
  }, [deleteListing, selectedRows])

  const handleOnCreateListingMenuClick = (value) => {
    navigate('/listings/create')
  }
  const handleConfirmDelete = () => {
    hideConfirmDelete()
    setCheckedRows([])
    setSelectedRows([])
    setIsListingLinked(false)
  }

  const handleChangeRows = (perPageRows) => {
    setrowsPerPage(perPageRows)
  }

  return (
    <div className={styles.listingsTableMain}>
      <Helmet label="Listings | RARE" />
      <section id="listings">
        {selectedListingType && (
          <CreateListing
            open={createOpen}
            setOpen={setCreateOpen}
            refetch={refetch}
            selectedListingType={selectedListingType}
          />
        )}

        <Row alignItems="center" marginBottom="40px" justifyContent="space-between" className={styles.listingDivMain}>
          <Col className={styles.listingInnerDiv}>
            <InputSearch
              name="search"
              onChange={(e) => {
                handleSearch(e.target.value.trimStart())
              }}
            />
          </Col>
          <Col>
            <div style={{ display: 'flex' }}>
              {user.role === USER_ROLES.agent && (
                <Button onClick={handleOnCreateListingMenuClick} label={'New sssss'} style={{ marginLeft: '25px' }}>
                  {'New Listing '}
                </Button>
              )}
              {canDelete && (
                <Button
                  onClick={showConfirmDelete}
                  disabled={selectedRows?.length > 0 ? false : true}
                  style={{ marginLeft: '25px' }}>
                  Delete
                </Button>
              )}
            </div>
          </Col>
        </Row>
        <CustomTabs options={tabOptions} onChange={handleStatusFilterChange}>
          {loading ? (
            <Loading fullpage />
          ) : (
            <BasicTable
              tableData={rows}
              tableColumns={columns}
              hanldeSelectedRowsData={handleRowSelect}
              displayRowCheckbox={canDelete}
              checkedRows={checkedRows}
              handleChangeRowsPerPage={handleChangeRows}
              rowsPerPage={rowsPerPage}
            />
          )}
        </CustomTabs>
      </section>
      <WarningPopup
        openModel={confirmDelete}
        loading={deleteLoading}
        confirmBtnLabel="Delete"
        onCancle={handleConfirmDelete}
        onConfirm={handleMultipleDelete}>
        {renderDeleteListingContent()}

        {isListingLinked ? (
          <>
            <Typography.Body type={5} style={{ textAlign: 'center', color: 'black' }}>
              Do you still want to delete this listing(s)?
            </Typography.Body>
            {selectedRows.length > 1 && (
              <Typography.Body type={1} style={{ textAlign: 'center' }}>
                {selectedRows.length} Listings selected
              </Typography.Body>
            )}
          </>
        ) : (
          <>
            <Typography.Body type={1}>Do you really want to delete this Listing(s)?</Typography.Body>
            {selectedRows.length > 1 && (
              <Typography.Body type={1} style={{ textAlign: 'center' }}>
                {selectedRows.length} Listings selected
              </Typography.Body>
            )}
          </>
        )}
      </WarningPopup>
    </div>
  )
}

const DELETE_LISTING = gql`
  mutation deleteListing($id: [String]!) {
    deleteListing(ids: $id)
  }
`

export default Listings
