import { Button, Row, Divider, Typography, Textarea } from 'components'
import { UserContext } from 'context'
import { convertUTCToLocal } from 'helpers/utils'
import { useContext, useState, useRef, useEffect } from 'react'
import { Controller } from 'react-hook-form'
import styles from './comments.module.scss'
import commentIcon from '../../assets/images/commentIcon.png'
import { Card, CardHeader, Box, CardContent, CardActions } from '@mui/material'

const Comments = ({ comments, control, handleSubmit, createLoading, onCancel }) => {
  const _comments = comments ? [...comments].sort((a, b) => (a.created < b.created ? -1 : 1)) : []

  const [page, setPage] = useState(1)
  const [commentValue, setCommentValue] = useState('')

  const { user } = useContext(UserContext)

  const handleCancel = () => {
    setCommentValue('')
    onCancel()
  }

  const commentContainerRef = useRef(null)

  useEffect(() => {
    if (commentContainerRef.current) {
      commentContainerRef.current.scrollTop = commentContainerRef.current.scrollHeight
    }
  }, [_comments])

  return (
    <div>
      <Box>
        <Card variant="outlined">
          <CardHeader
            avatar={<img src={commentIcon} alt="commentIcon" className={styles.commentIcon} />}
            title={
              <Typography.Subheading type={6} className={styles.commentTitle}>
                Comment History
              </Typography.Subheading>
            }
            className={styles.commentCardHeader}
          />
          <Divider className={styles.commentHeaderDivider} />
          <div
            ref={commentContainerRef}
            style={{ overflowY: 'auto', height: "35vh",marginTop: 10, width: 350}}
            className={styles.commentsContainer}>
            <CardContent className={styles.commentContent}>
              {_comments.map((comment, i) => (
                <div
                  key={i}
                  className={`${styles.commentWrapper} ${
                    comment.sender && comment.sender.id === user.id
                      ? styles.commentBubbleRight
                      : styles.commentBubbleLeft
                  }`}>
                  <div className={styles.nameDateDiv}>
                    <div className={styles.commentHeader}>
                      <Typography.Subheading type={3} className={styles.senderName}>
                        {comment.sender && comment.sender.name}
                      </Typography.Subheading>
                      <Typography.Body
                        type="4"
                        className={styles.date}
                        style={{
                          textAlign: comment.sender && comment.sender.id === user.id ? 'right' : 'left'
                        }}>
                        {convertUTCToLocal(comment.created, 'MMMM D, YYYY hh:mm A')}
                      </Typography.Body>
                    </div>
                    <div>
                      <Typography.Body type="3" className={styles.message}>
                        {comment.message}
                      </Typography.Body>
                    </div>
                  </div>
                </div>
              ))}
            </CardContent>
          </div>

          <CardActions className={styles.cardInputSection}>
            <div style={{ backgroundColor: ' #f5f5f5', width: '100%' }}>
              <div style={{ padding: '10px' }}>
                <Controller
                  name="comment"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Textarea
                      value={value}
                      type={5}
                      onChange={(e) => {
                        onChange(e.target.value.trimStart())
                        setCommentValue(e.target.value.trimStart())
                      }}
                      name="comment"
                      placeholder="Write your comment..."
                      rows={2}
                      style={{
                        width: '100%',
                        maxWidth: 'none',
                        height: '90px',
                        color: '#999',
                        fontFamily: 'Source Sans Pro'
                      }}
                      error={error?.message}
                    />
                  )}
                />
              </div>

              <Row gap={10} style={{ float: 'right', padding: '0px 10px 10px 10px' }}>
                <Button onClick={handleCancel} type="tertiary">
                  Cancel
                </Button>
                <Button onClick={handleSubmit} loading={createLoading}>
                  Send
                </Button>
              </Row>
            </div>
          </CardActions>
        </Card>
      </Box>
    </div>
  )
}

export default Comments
